/* eslint-disable @next/next/no-img-element */
import Image from 'next/image';
import { get } from 'lodash';
import { useRouter } from 'next/router';
import { useState } from "react";
import AppleLogin from 'react-apple-login';
import FacebookLogin from 'react-facebook-login';
import GoogleLogin from 'react-google-login';
import { setCookie } from "framework/libs/cookie";
import { TOKEN_CONGDONG, TOKEN_SHOP, TOKEN_CHAT } from 'framework/store/constants';
import http from "utilities/http";
import { toast } from "react-toastify";
import { useCart } from "framework/store";
import { Loader } from "components/loader";

export default function Index({ }) {
    const [loading, setLoading] = useState(false);

    const [stateStore] = useCart();
    const lastRoute = stateStore.lastRoute;
    const router = useRouter();

    const setCookieCLient = (json) => {
        setCookie(TOKEN_CONGDONG, json.data.token);
        setCookie(TOKEN_SHOP, json.data.shop_token);
        setCookie(TOKEN_CHAT, json.data.chat_token);
    };

    const responseFacebook = async (response) => {
        setLoading(true);
        const res = await http('auth/login/social/facebook', {}, {
            method: 'POST',
            body: {
                access_token: response.accessToken,
                device_name: 'Iphone'
            }
        });
        setLoading(false);
        if (get(res, ['data', 'token'])) {
            setCookieCLient(res);
            router.push(lastRoute || '/');
        } else {
            toast.error(res.message || 'Đã xảy ra lỗi, vui lòng thử lại!');
        }
    };
    const responseGoogle = async (response) => {
        setLoading(true);
        const res = await http('auth/login/social/google', {}, {
            method: 'POST',
            body: {
                google_id: response?.googleId,
                google_email: response?.profileObj?.email || response?.it?.Tt,
                google_fullname: response?.profileObj?.name,
                google_gender: 'male',
                device_name: 'Iphone',
            }
        }, 'v2');
        setLoading(false);
        if (get(res, ['data', 'token'])) {
            setCookieCLient(res);
            router.push(lastRoute || '/');
        } else {
            // toast.error(res.message || 'Đã xảy ra lỗi, vui lòng thử lại!');
        }
    };
    const responseApple = async (response) => {
        setLoading(true);
        const res = await http('auth/login/social/apple/callback', {}, {
            method: 'POST',
            body: {
                code: response?.authorization?.id_token,
                device_name: 'Iphone'
            }
        }, 'v2');
        setLoading(false);
        if (get(res, ['data', 'token'])) {
            setCookieCLient(res);
            router.push(lastRoute || '/');
        } else {
            toast.error(res.message || 'Đã xảy ra lỗi, vui lòng thử lại!');
        }
    };

    return <div className="loginShare">
        <Loader loading={loading} />
        <div className="loginShare-or"><span>Hoặc đăng nhập nhanh với</span></div>
        <ul className="loginShare-item">
            <li>
                <AppleLogin
                    clientId="vn.happynest.api"
                    // redirectURI="https://9020-42-113-55-33.ngrok.io/login"
                    redirectURI="https://happynest.vn/login"
                    callback={responseApple}
                    responseType={'code id_token'}
                    responseMode="form_post"
                    render={renderProps => (
                        <button><Image src="/images/icon-appleLogin.svg" width={48} height={48}
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                            alt="" /></button>
                    )}
                    usePopup={true}
                />
            </li>
            <li>
                <GoogleLogin
                    clientId="742582317497-hfunc8lp8jfkc8qqtc5no8fu0kf6hs65.apps.googleusercontent.com"
                    onSuccess={responseGoogle}
                    onFailure={responseGoogle}
                    cookiePolicy={'single_host_origin'}
                    render={renderProps => (
                        <button><Image src="/images/icon-googleLogin.svg" width={48} height={48}
                            onClick={renderProps.onClick}
                            disabled={renderProps.disabled}
                            alt="" /></button>
                    )}

                />
            </li>
            <li><FacebookLogin
                appId="984476728630070"
                autoLoad={false}
                fields="name,email,picture,gender"
                cssClass="p-0"
                cookie={true}
                xfbml={true}
                redirectUri='https://happynest.vn/login'
                callback={responseFacebook}
                version={"20.0"}
                icon={<Image src="/images/icon-facebookLogin.svg" width={48} height={48} alt="" />}
                textButton={''}
            />
            </li>
        </ul>
    </div>;
}
