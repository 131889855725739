import Slider from "react-slick";
import Image from 'next/image';
import { useForm } from 'react-hook-form';
import { get } from 'lodash';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { setCookie } from "framework/libs/cookie";
import { TOKEN_CONGDONG, TOKEN_SHOP, TOKEN_CHAT } from 'framework/store/constants';
import http from "utilities/http";
import Error from "components/error";
import { Loader } from "components/loader";
import { useState } from "react";
import { useCart } from "framework/store";
import QuickLogin from "components/login/dang-nhap-nhanh";
import ga4 from "utilities/ga4";

export default function login() {
    const [stateStore, actions] = useCart();
    const lastRoute = stateStore.lastRoute;
    const { register, handleSubmit, setError, formState: { errors } } = useForm();
    const router = useRouter();
    const redirectLink = router.query.redirect;
    const settings = {
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    const setCookieCLient = (json) => {
        setCookie(TOKEN_CONGDONG, json.data.token);
        setCookie(TOKEN_SHOP, json.data.shop_token);
        setCookie(TOKEN_CHAT, json.data.chat_token);
    };
    const login = async (value) => {
        ga4().login('Happynest');

        setLoading(true);
        const res = await http('auth/login', {}, {
            method: 'POST',
            body: {
                email: value.email,
                password: value.password,
                device_name: 'Iphone'
            }
        }, 'v2');

        if (get(res, ['data', 'token'])) {
            setCookieCLient(res);
            router.push(lastRoute || redirectLink || '/');
        } else {
            setLoading(false);
            setError('password', {
                type: 'manual',
                message: res.message
            });
        }
    };
    const [loading, setLoading] = useState(false);

    return <div className="login">
        <Loader loading={loading} />

        <div className="login-info">
            <div className="loginWrap">
                <div className="logo"><a href="#"><Image src="/images/logo.svg" alt="" width={248} height={57} /></a>
                </div>
                <div className="slider sliderBanner">
                    <Slider {...settings}>
                        <div className="bannerWrap">
                            <div className="bannerThumb">
                                <Image src="/images/banner-login.svg" alt="" width={357} height={364} />
                            </div>
                            <div className="bannerText">
                                <p className="tlt">Tìm kiếm ý tưởng tuyệt vời!</p>
                                <p className="text">Bạn cần ý tưởng, cảm hứng về chuyện nhà, Happynest luôn có, giúp bạn
                                    dễ dàng tìm được ý tưởng tuyệt vời.</p>
                            </div>
                        </div>
                        <div className="bannerWrap">
                            <div className="bannerThumb">
                                <Image src="/images/banner-login.svg" alt="" width={357} height={364} />
                            </div>
                            <div className="bannerText">
                                <p className="tlt">Tìm kiếm ý tưởng tuyệt vời!</p>
                                <p className="text">Bạn cần ý tưởng, cảm hứng về chuyện nhà, Happynest luôn có, giúp
                                    bạn
                                    dễ dàng tìm được ý tưởng tuyệt vời.</p>
                            </div>
                        </div>
                        <div className="bannerWrap">
                            <div className="bannerThumb">
                                <Image src="/images/banner-login.svg" alt="" width={357} height={364} />
                            </div>
                            <div className="bannerText">
                                <p className="tlt">Tìm kiếm ý tưởng tuyệt vời!</p>
                                <p className="text">Bạn cần ý tưởng, cảm hứng về chuyện nhà, Happynest luôn có, giúp
                                    bạn
                                    dễ dàng tìm được ý tưởng tuyệt vời.</p>
                            </div>
                        </div>
                    </Slider>
                </div>
            </div>
        </div>
        <div className="login-form">
            <div className="formWrap">
                <form onSubmit={handleSubmit(login)}>
                    <h1 className="title">Bắt đầu hành trình của <br />tổ ấm cùng Happynest
                    </h1>
                    <p className="form-note">Đăng nhập để tham gia cộng đồng</p>
                    <div className="groupForm">
                        {/*form-control-danger*/}
                        <input className="form-control"
                            type="text"  {...register('email', {
                                required: 'Bạn cần nhập email',
                                pattern: {
                                    value: /\S+/,
                                    message: "Tài khoản không đúng định dạng. Hãy thử lại"
                                }
                            })}
                            placeholder="Nhập số điện thoại/email/tên tài khoản" />
                        <Error errors={errors} name='email' />
                    </div>
                    <div className="groupForm">
                        <input className="form-control" type="password" placeholder="Nhập mật khẩu"
                            {...register('password', { required: 'Bạn cần nhập mật khẩu' })} />
                        <Error errors={errors} name='password' />
                    </div>
                    <div className="groupForm-btn">
                        <button type="submit" className="btn btn--blue">ĐĂNG NHẬP</button>
                    </div>
                </form>


                <div className="login-text">
                    <p className="login-text-p">Bạn chưa có tài khoản? <Link href="/register">Đăng ký</Link></p>
                    <p className="login-text-p"><Link href="forgot-password">Quên mật khẩu?</Link></p>
                </div>
                <QuickLogin />
                {/* <p className="loginRegistration"><a href="#">Đăng ký trở thành chuyên gia</a></p> */}
                <p className="loginOverlook"><Link href="/"><a>Bỏ qua</a></Link></p>
            </div>
            <p className="copyright">© Happynest, JSC.. All Rights Reserved</p>
        </div>
    </div>;

}
