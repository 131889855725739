import { ErrorMessage } from "@hookform/error-message";

export default function Error({ errors, name }) {
    return <ErrorMessage errors={errors} name={name}
        render={({ message }) => {
            return <p
                style={{ color: 'red', 'fontSize': '12px' }}
                className='mt-1'
            >
                {message}
            </p>;
        }}
    />;
}

