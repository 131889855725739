import {GA4_API_SECRET, MEASUREMENT_ID} from './constants.js';

export default function ga4() {
    const login = async (_method) => {
        try {
            // console.log("GA4 send event login", MEASUREMENT_ID, _method);
            gtag('get', MEASUREMENT_ID, 'client_id', (clientID) => {
                // console.log("GA4 send event login", MEASUREMENT_ID, clientID, _method);
                fetch(`https://www.google-analytics.com/debug/mp/collect?measurement_id=${MEASUREMENT_ID}&api_secret=${GA4_API_SECRET}`, {
                    method: "POST",
                    body: JSON.stringify({
                        "client_id": clientID,
                        "events": [{
                            "name": 'login',
                            "params": {
                                "method": _method
                            },
                        }]
                    })
                });
            });
        } catch (e){
            console.log("GA4 event login", e);
        }
    };

    return { login };
}
